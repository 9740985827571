.parallax-image {
    background-attachment: scroll;
    background-position: center center;
    background-size: cover; 
}

@media screen and (min-width: 992px) {
    .parallax-image {
        background-attachment: fixed;
    } 
}