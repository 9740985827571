@import "~bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700,900');

body {
    font-family: 'Roboto',  sans-serif;
    font-size: 20px;
}

h1 {
    font-weight: bold;
    font-size: 3em;

    @media screen and (min-width: 800px) {
        font-size: 6em;
    }
}

h2 {
    font-weight: 100;
    font-size: 1em;
    color:#5d7f39; 

    @media screen and (min-width: 800px) {
        font-size: 2em;
    }   
}

h3 {
    font-size: 2em;
    font-weight: 100;
    text-align: center;
    color:#5d7f39;

    @media screen and (min-width: 800px) {
        font-size: 4em;
    }
}

h4 {
    font-size: 2em;
    font-weight: 100;
    color:#5d7f39;
}

h5 {
    font-size: 1.2em;
    font-weight: 700;
    color:#5d7f39;
}

strong {
    font-weight: bold;
}