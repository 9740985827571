.indicador {
    text-align: center;
    strong {
        display: block;
        font-size: 2em;
        font-weight: 900;
    }
    p {
        max-width: 440px;
        display: inline-block;
    }
    img {
        max-height: 120px;
    }
}   