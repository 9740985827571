.title {
    padding: 15px 10%;
    text-align: center;

    .logo {
        img.logoue {
            max-height: 100px;
            
            @media screen and (max-width: 800px) {
                margin-bottom: 40px;
            }   
        }

        img.logoiica {
            max-height: 80px;
        }
    }
}